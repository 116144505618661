<template>
  <app-page container>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.general_info")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/edit-profile" class="decoration-none edit-text">{{
        $vuetify.lang.t("$vuetify.edit")
      }}</router-link>
    </template>

    <ul class="list">
      <li class="list--item" v-for="info in generlInfo" :key="info.label">
        <p class="label">{{ info.label }}</p>
        <p class="text">{{ info.value }}</p>
      </li>
      <li class="list--item">
        <p class="label">{{ $vuetify.lang.t("$vuetify.password") }}</p>
        <p class="text">
          <v-icon v-for="n in 6" :key="n" size="10px" color="dark"
            >mdi-circle</v-icon
          >
          <router-link
            class="decoration-none ml-2"
            style="font-size: 12px"
            to="/change-password"
            >{{ $vuetify.lang.t("$vuetify.change") }}</router-link
          >
        </p>
      </li>
      <li class="list--item">
        <p class="label">{{ $vuetify.lang.t("$vuetify.language") }}</p>
        <p class="text">
          {{ currentLanguage }}
          <v-menu left origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <span
                style="font-size: 12px"
                class="primary--text"
                v-bind="attrs"
                v-on="on"
              >
                {{ $vuetify.lang.t("$vuetify.change") }}
              </span>
            </template>

            <v-list
              v-for="(lang, index) in languages"
              :key="index"
              :color="lang.title === currentLanguage ? 'primary' : ''"
            >
              <v-list-item @click="changeLanguage(lang)">
                <v-list-item-title>{{ lang.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </p>
      </li>
    </ul>
    <!-- kids -->
    <p class="text-right mb-0 pt-2">
      <router-link to="/preference-1" class="decoration-none edit-text">{{
        $vuetify.lang.t("$vuetify.edit")
      }}</router-link>
    </p>
    <ul class="list pt-0">
      <li class="list--item" v-for="info in kids" :key="info.label">
        <p class="label">{{ info.label }}</p>
        <p class="text">{{ info.value }}</p>
      </li>
    </ul>
    <!-- garden -->
    <ul class="list">
      <li class="list--item" v-for="info in garden" :key="info.label">
        <p class="label">{{ info.label }}</p>
        <p class="text">{{ info.value }}</p>
      </li>
    </ul>
    <!-- other animals -->
    <ul class="list">
      <li class="list--item" v-for="info in otherAnimals" :key="info.label">
        <p class="label">{{ info.label }}</p>
        <p class="text">{{ info.value }}</p>
      </li>
    </ul>

    <a
      href="javascript:void(0)"
      class="decoration-none red--text mt-10 text-center d-block"
      @click="dialog = true"
      >Delete Account</a
    >

    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-card>
        <v-card-title class="text-h5" style="word-break: break-word">
          Are you sure you want to delete the account?
        </v-card-title>
        <v-card-text>
          <v-row class="text-center">
            <v-col cols="6">
              <v-btn
                class="ma-2"
                color="primary"
                text
                @click="DELETE_ACCOUNT"
                :loading="loading"
              >
                Yes
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-2" icon color="primary" @click="dialog = false">
                no
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </app-page>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");

import { GARDEN_SIZES, LANGUAGES } from "@/constants";

export default {
  name: "general-info",
  data() {
    return {
      currentLanguage: LANGUAGES[0].title,
      dialog: false,
    };
  },
  computed: {
    ...mapState(["user", "loading"]),
    generlInfo() {
      const { name, contact, email } = this.user;
      return [
        {
          label: this.$vuetify.lang.t("$vuetify.name"),
          value: name,
        },
        {
          label: this.$vuetify.lang.t("$vuetify.email"),
          value: email,
        },
        {
          label: this.$vuetify.lang.t("$vuetify.phone"),
          value: contact,
        },
      ];
    },
    kids() {
      const { meta_data } = this.user;
      if (!meta_data) {
        return [
          {
            label: this.$vuetify.lang.t("$vuetify.kids"),
            value: "--",
          },
        ];
      } else {
        const { kidsAges, noOfKids } = meta_data;
        let arr = [];
        for (let i = 1; i <= noOfKids; i++) {
          arr.push({
            label: `${this.$vuetify.lang.t(
              "$vuetify.kid"
            )} #${i} ${this.$vuetify.lang.t("$vuetify.age")}`,
            value: kidsAges[i - 1],
          });
        }
        return [
          {
            label: this.$vuetify.lang.t("$vuetify.kids"),
            value: noOfKids,
          },
          ...arr,
        ];
      }
    },
    garden() {
      const { meta_data } = this.user;
      if (!meta_data) {
        return [
          {
            label: this.$vuetify.lang.t("$vuetify.garden"),
            value: "--",
          },
        ];
      } else {
        const { garden_size } = meta_data;
        if (!garden_size)
          return [
            {
              label: this.$vuetify.lang.t("$vuetify.garden"),
              value: "--",
            },
          ];
        else {
          let value = GARDEN_SIZES[0].text;
          const index = GARDEN_SIZES.findIndex((g) => g.value === garden_size);
          if (index != -1) {
            value = GARDEN_SIZES[index].text;
            let val_in_array = value.split(" ");
            let formating_array = val_in_array.map((val) => {
              return val.toLowerCase();
            });
            value = formating_array.join("_");
          }
          return [
            {
              label: this.$vuetify.lang.t("$vuetify.garden"),
              value: this.$vuetify.lang.t(`$vuetify.${value}`),
            },
          ];
        }
      }
    },
    otherAnimals() {
      const { meta_data } = this.user;
      if (!meta_data) {
        return [
          {
            label: this.$vuetify.lang.t("$vuetify.animal_in_house"),
            value: "--",
          },
        ];
      } else {
        const { otherAnimalsTypes, otherAnimals } = meta_data;
        let arr = [];
        for (let i = 1; i <= otherAnimals; i++) {
          let type = otherAnimalsTypes.map((type) => {
            return type.toLowerCase().split(" ").join("_");
          });
          arr.push({
            label: `${this.$vuetify.lang.t("$vuetify.animal")} ${i}`,
            value: this.$vuetify.lang.t(`$vuetify.${type[i - 1]}`),
          });
        }
        return [
          {
            label: this.$vuetify.lang.t("$vuetify.animal_in_house"),
            value: otherAnimals,
          },
          ...arr,
        ];
      }
    },
    languages() {
      return LANGUAGES;
    },
  },
  methods: {
    ...mapActions(["DELETE_ACCOUNT"]),
    changeLanguage(lang) {
      this.currentLanguage = lang.title;
      localStorage.setItem("lang", lang.value);
      this.$vuetify.lang.current = lang.value;
    },
  },
  created() {
    this.currentLanguage = LANGUAGES.find(
      (lang) => lang.value === localStorage.getItem("lang")
    ).title;
  },
};
</script>

<style lang="scss" scoped>
.list {
  p {
    margin: 0;
  }

  list-style-type: none;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;

    p.label {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      opacity: 0.5;
    }

    p.text {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }
  }
}

.edit-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
</style>
